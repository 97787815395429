<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row type="flex" justify="between" :gutter="50">
        <el-col :span="18">
          <div class="title"><h3>营业额概况</h3></div>
          <el-row class="card-box" type="flex" justify="space-between">
            <div class="card-box-item" @click="goOrderManage('checkinCount')">
              <p class="item-tit">今日预抵</p>
              <p class="item-text">{{statisticsStateList.checkinCount || 0}}</p>
            </div>
            <div class="card-box-item" @click="goOrderManage('checkoutCount')">
              <p class="item-tit">今日预离</p>
              <p class="item-text">{{statisticsStateList.checkoutCount || 0}}</p>
            </div>
            <div class="card-box-item d-flex d-flex-y-center" @click="goOrderManage('liveCount')">
              <div>
                <p class="item-tit">在住数量</p>
                <p class="item-text">{{statisticsStateList.liveCount || 0}}</p>
              </div>
              <!-- <div class="m-left-auto">
                <i size="50" type="text" class="el-icon-caret-top font-18">0%</i>
                <p class="font-14">日环比</p>
              </div> -->
            </div>
            <div class="card-box-item d-flex d-flex-y-center" @click="goOrderManage('notRoomCount')">
              <div>
                <p class="item-tit">未排房</p>
                <p class="item-text">{{statisticsStateList.notRoomCount || 0}}</p>
              </div>
              <!-- <div class="m-left-auto">
                <div class="d-flex">
                  <p class="item-rect" v-for="(item,idx) in 5" :class="{'item-rect-ac':idx < 3}" :key="idx"></p>
                </div>
                <p class="font-14">紧急程度</p>
              </div>   -->
            </div>
            <!-- <div class="card-box-item d-flex d-flex-y-center" @click="goOrderManage('待处理')">
              <div>
                <p class="item-tit">待处理</p>
                <p class="item-text">0</p>
              </div>
              <div class="m-left-auto">
                <div class="d-flex">
                  <p class="item-rect" v-for="(item,idx) in 5" :class="{'item-rect-ac':idx < 3}" :key="idx"></p>
                </div>
                <p class="font-14">紧急程度</p>
              </div>
            </div> -->
          </el-row>
          <div class="title m-top-50"><h3>近七日入住率</h3></div>
          <div class="chart-box">
            <div id="chart"></div>
          </div>
          <!-- <div class="p-20">
            <el-row type="flex"  justify="space-between">
              <h3>常用功能</h3>
              <span class="el-icon-edit"></span>
            </el-row>
          </div> -->
        </el-col>
        <el-col :span="6" class="right-remark">
            <p class="title font-18">
              备忘录
               <span style="color:#999;font-size:13px;">{{textareaUpdateTime}} <span v-if="textareaUpdateTime">自动保存成功</span> </span>
            </p>
          <el-input
            type="textarea"
            :rows="28"
            placeholder="请输入内容"
            v-model="textarea"
            resize="none"
            @input="setMemo">
          </el-input>
          <!-- <div class="server">
            <div class="server-tit">在线客服</div>
            <div class="server-sub-tit">专业咨询团队，为您全方位解答</div>
            <el-button round type="primary" class="server-call">现在咨询</el-button>
          </div> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import { getStatisticsState, getOccupancyRate, getOne, setMemo } from '@/api/pms/orderManage/orderManage';
import { mapState } from "vuex";
import { dateFactory } from '@/common/js/common'
export default {
  name: 'arrivingTodaySummary',
  data() {
    return {
      textarea:"",
      textareaUpdateTime:"", // 备忘录最后修改时间
      textareaT:"", // 备忘录节流
      crumbs: new Map([
        ['PMS'], ['首页']
      ]),
      statisticsStateList:{
        checkinCount:0,
        checkoutCount:0,
        liveCount:0,
        notRoomCount:0
      },
      dateRange: [],
      name:'first',
      option : {
        tooltip: {
          trigger: 'axis',
          formatter: function(arg) {
              return arg[0].name + '<br /> ' + arg[0].data.toFixed(2) + "%"
          }
        },
        xAxis: {
          data: ['2022/02/11', '2022/02/12', '2022/02/13', '2022/02/14', '2022/02/15','2022/02/16','2022/02/17']
        },
        yAxis: {
          axisLabel: {
            show: true,  
            interval: 'auto',  
            formatter: '{value} %'  
          },
          // show:false,
          // axisTick:{ // y轴刻度线
          //   show:false
          // },
          // splitLine:{
          //   show:false
          // },
          // axisLine:{ // y轴
          //   show:false
          // },
        },
        series: [
          {
            triggerLineEvent:true,
            data: [10, 22, 28, 23, 19, 130, 68 ],
            type: 'line',
            lineStyle:{
              color:"#006FFF"
            }
          }
        ]
      }
    }
  },
  mounted(){
    this.getStatisticsState()
    this.getOccupancyRate()
    this.getOne()
  },
  methods: {
    getOne(){ // 查询备忘录
      let params = {
        hotelId:this.hotelInfo.id
      }
      getOne(params).then( res => {
        this.textarea = res.records.content
        this.textareaUpdateTime = res.records.updateTime.split(" ")[1]
      })
    },
    setMemo(){
      let params = {
        hotelId:this.hotelInfo.id,
        content:this.textarea
      }
      clearTimeout(this.textareaT)
      this.textareaT = setTimeout( ()=>{
        setMemo(params).then( res => {
          this.textareaUpdateTime = dateFactory.timestampFormat(new Date().getTime()/1000, true).split(" ")[1]
          clearTimeout(this.textareaT)
          this.textareaT = null
        })
      },3000)
    },
    listsAdd(){
      this.lists.push(11)
    },
    listsSplice(){
      this.lists.splice(0,1)
    },
    getOccupancyRate(){ // 获取近七日入住率
      let data = {
        hotelId:this.hotelInfo.id,
        beginDate:dateFactory.getDistanceToday(-7,false),
        endDate:dateFactory.getDistanceToday(-1,false)
      }
      getOccupancyRate(data).then( res => {
        let xAxis = [],
            data = []
        res.records.forEach( item => {
          xAxis.push(item.date)
          data.push(item.rate)
        })
        this.option.xAxis.data = xAxis
        this.option.series[0].data = data
        this.initEchart()
      })
    },
    getStatisticsState(){ // 单日统计数据
      let data = {
        startTime:dateFactory.getDistanceToday(-1,false) + " 00:00:00",
        endTime:dateFactory.getDistanceToday(0,false) + " 00:00:00",
        hotelId:this.hotelInfo.id
      }
      getStatisticsState(data).then( res => {
        for(let key in res.records){
          this.statisticsStateList[key] = res.records[key]
        }
      })
    },
    // 获取订单时间
   initEchart(){
      var myChart = echarts.init(document.getElementById('chart'));
      myChart.setOption(this.option)
   },
   goOrderManage(state){
     this.$router.push({path:"/pms/order_manage",query:{state}})
   }
  },
  computed: { ...mapState(['hotelInfo', 'dictData']) },
  watch: {
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.getStatisticsState() // 获取概况
        this.getOccupancyRate() // 获取近七日入住率
        this.getOne() // 获取备忘录
      }
    }
  }
}
</script>
<style scoped lang="scss">
.m-left-auto{
  margin-left:auto;
}
.d-flex-y-center{
  align-items:center;
}
.cont {
  .content-box{
    .title { margin: 20px 0; }
    .card-box {
      $gradient-blue: linear-gradient(90deg, #006FFF, #189AFF);
      $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
      $gradient-org: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
      $gradient-yellow: linear-gradient(90deg, rgb(255, 161, 50), rgb(255, 209, 50));
      $gradient-red: linear-gradient(90deg, rgb(163, 50, 255), rgb(151, 99, 233));
      $list-bg: $gradient-blue, $gradient-green, $gradient-org, $gradient-yellow,$gradient-red;
      .card-box-item {
        width: 22%; padding:30px 40px; background-repeat: no-repeat;
        text-align: left; background-position: 0 0; background-size: 100% 100%;
        box-sizing: border-box; color: #FFFFFF; border-radius: 6px;
        @each $c in $list-bg {
          $i: index($list-bg, $c);
          &:nth-child(#{$i}) { background: nth($list-bg, $i); }
        }
        .item-tit { font-size: 18px; font-weight: 400; }
        .item-text { font-size: 36px; font-weight: 700 }
        .item-rect{
          background:#F1F1F1;
          width:10px;
          height:18px;
          margin-right:3px;
        }
        .item-rect-ac{
          background:#FF2332
        }
      }
    }
    .chart-box{
      width:100%;
      #chart{
        width:90%;
        height:400px;
      }
    }
    .right-remark{
      box-shadow: -5px 0px 5px -5px #CCC;
      .server{
        margin-top:20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 0;
        background-image: url(../../../assets/pms-index-server.png);
        padding:20px;
        .server-tit{
          font-size: 18px;
        }
        .server-sub-tit{
          margin:10px 0;
          font-size: 15px;
          color: #B9B9B9;
        }
        .server-call{
          margin-top:20px;
        }
      }
      // <div class="server">
      //   <div class="server-tit">在线客服</div>
      //   <div class="server-sub-tit">专业咨询团队，为您全方位解答</div>
      //   <div class="server-call">现在咨询</div>
      // </div>
    }
  }
}
</style>
