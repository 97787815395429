import request from "@/api/service";
import qs from 'qs';

const api = {
   // 获取房态日历
  getPmsOrderList:`/pms/bookInfo/getBookInfoPage`,
  getStatisticsState:'/pms/checkinRoom/getBookCount',
  getOccupancyRate:'/pms/checkinRoom/getOccupancyRate',
  getOne:'/pms/memo/getOne',
  setMemo:'/pms/memo/setMemo',
  // 查询房态日志
  getRoomStateLog:'/pms/roomLogs/getPage',
  // 订单导出
  bookInfoExcel:'/pms/bookInfo/bookInfoExcel',
  confirmBook:'/pms/bookInfo/confirmBook',
  declineBook:'/pms/bookInfo/declineBook',
  shiftInfo: '/pms/shiftrecord/getShiftInfo',
  shiftRecord: '/pms/shiftrecord'
}
export function getPmsOrderList(data){
  return request({
    url: api.getPmsOrderList + `?page=${data.page}&limit=${data.limit}`,
    method: 'post',
    headers:{"responseType": 'JSON'},
    data,
  })
}

export function getStatisticsState(data){
  return request({
    url:api.getStatisticsState,
    method:'post',
    // headers:{"resoinseType":'JSON'},
    data
  })
}

export function getOccupancyRate(data){
  return request({
    url:api.getOccupancyRate,
    method:'post',
    data
  })
}

export function getOne(params){
  return request({
    url:api.getOne,
    method:'get',
    params
  })
}

export function setMemo(data){
  return request({
    url:api.setMemo + `?content=${data.content}&hotelId=${data.hotelId}`,
    method:'post'
  })
}

export function getRoomStateLog(data){
  return request({
    url:api.getRoomStateLog + `?page=${data.page}&limit=${data.limit}`,
    method:'post',
    // headers:{"responseType": 'JSON'},
    data
  })
}

export function bookInfoExcel(params) {
  return request({
     url: api.bookInfoExcel,
     method: 'post',
     data:params,
     responseType: 'blob'
  })
}

export function confirmBook(params){
  return request({
    url:api.confirmBook + `?id=${params.id}`,
    method:'post'
  })
}

export function declineBook(params){
  return request({
    url:api.declineBook + `?id=${params.id}`,
    method:'post'
  })
}

export function getShiftInfo(params) {
  return request({
    url: api.shiftInfo,
    method: 'GET',
    params
  })
}

export function saveShiftRecord(params) {
  return request({
    url: api.shiftRecord,
    method: 'POST',
    data: params
  })
}
